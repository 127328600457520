import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CropFreeIcon from '@mui/icons-material/CropFree';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

interface IProps {
  loading: boolean;
  createScan: (data: string, x: number, y: number, z: number) => void;
  router: any;
  language: string;
}
interface IState {
  interfaceType: 'location' | 'item';
}

const scanner = (callback: (data: string) => void) => {
  // @ts-ignore
  if (window.MessageInvoker) {
    // @ts-ignore
    window.MessageInvoker.postMessage('scan');
  }

  const interval = setInterval(() => {
    // @ts-ignore
    const data = window.nativeData;

    if (data) {
      // @ts-ignore
      window.nativeData = undefined;

      clearInterval(interval);

      callback(data);
    }
  }, 200);

  setTimeout(() => {
    if (interval) {
      clearInterval(interval);
    }
  }, 120000);
};

const StyledButton = styled(Button)({
  position: 'absolute',
  zIndex: 1,
  bottom: 20,
  left: 40,
  right: 40,
  backgroundColor: 'yellow',
  color: '#333',
  margin: '0 auto',
});


class ScanPlaceholder extends Component<IProps, IState> {
  private timeout?: NodeJS.Timeout;

  constructor(props: IProps) {
    super(props);

    this.state = {
      interfaceType: props.router.params.type || 'location',
    };

    this.scan = this.scan.bind(this);
  }
  
  public componentDidMount() {
    this.timeout = setTimeout(() => {
      this.scan();
    }, 2500);
  }

  public scan() {
    const { createScan, language, router } = this.props;

    scanner((data: string) => {
      if (data) {
        createScan(data, 0, 0, 0);

        router.navigate(`/${language}/dashboard/routing/${router.params.floorplanId || ''}/scan/${router.params.type}/${data}`);
      }
    });
  }

  public componentWillUnmount(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  public render() {
    const { interfaceType } = this.state;

    return (
      <Box
        component={Link}
        className="tab-content-inner"
        style={{
          position: 'relative',
          backgroundImage: 'url("/assets/scanning.png")',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#F1F1F1',
          backgroundPosition: 'center',
          backgroundSize: '75%, auto',
        }}
        onClick={this.scan}
      >
        <Box
          style={{
            position: 'absolute',
            top: 0,
            width:'100%',
            backgroundColor: 'yellow',
            textAlign: 'center',
            color: '#333',
          }}
          p={4}
        >
          <Typography variant="h2" style={{ fontWeight: 700 }}>Scan {interfaceType === 'location' ? 'Location' : 'Item'}</Typography>
        </Box>
      
        {interfaceType === 'location' && (
          <StyledButton onClick={this.scan}>
            <CropFreeIcon fontSize="large" />

            <Box pl={1}>
              <Typography variant="inherit">Auto Locate</Typography>
            </Box>
          </StyledButton>
        )}
      </Box>
    );
  }
}

export default ScanPlaceholder;
