import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { createScan } from '@actions/index';
import { IAppState } from '@reducers/index';
import { withRouter } from '@app/utils/withRouter';
import { getActiveLanguage } from '@selectors/app';
import ScanPlaceholder from './ScanPlaceholder';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  loading: state.scans.scan.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createScan: (scan: string, x: number, y: number, z: number) =>
    dispatch(createScan.request({ scan, type: 'location', x, y, z }))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScanPlaceholder));
