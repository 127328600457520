import { connect } from 'react-redux';

import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import ScanResult from './ScanResult';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
});

export default withRouter(connect(mapStateToProps)(ScanResult));
