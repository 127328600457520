import React, { Component } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { borderRadius } from 'react-select/lib/theme';

interface IProps {
  router: any;
  language: string;
}
interface IState {
  interfaceType: 'location' | 'item';
  data: string;
  confirmed: boolean;
}

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  bottom: 40,
  left: 0,
  right: 0,
  margin: '0 auto',
});

const StyledQtFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  right: 0,
  bottom: -25,
});


class ScanPlaceholder extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      interfaceType: props.router.params.type || 'location',
      data: props.router.params.data || '',
      confirmed: false,
    };

    this.confirm = this.confirm.bind(this);
  }

  public confirm() {
    const { language, router } = this.props;
    const { confirmed, interfaceType } = this.state;

    if (!confirmed) {
      return this.setState({ confirmed: true });
    }

    if (confirmed && interfaceType === 'item') {
      return router.navigate(`/${language}/dashboard/routing/${router.params.floorplanId || ''}/done`);
    }

    router.navigate(`/${language}/dashboard/routing/${router.params.floorplanId || ''}/scan/item`);
  }

  public render() {
    const { confirmed, data, interfaceType } = this.state;

    return (
      <Box className="tab-content-inner" style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Box
          style={{
            position: 'absolute',
            top: 0,
            width:'100%',
            backgroundColor: confirmed ? '#80ff33' : '#F1F1F1',
            textAlign: 'center',
            color: '#333',
          }}
          p={4}
        >
          <Typography variant="h2" style={{ fontWeight: 700 }}>{interfaceType === 'location' ? 'Location' : 'Item'}</Typography>
        </Box>

        <Box
          style={{
            position: 'relative',
            backgroundColor: confirmed ? '#80ff33' : '#F1F1F1',
            textAlign: 'center',
            color: '#333',
            borderRadius: '12px',
          }}
          p={3}
        >
          <Typography variant="h4">{data}</Typography>

          {interfaceType !== 'location' && (<StyledQtFab color={confirmed ? 'success' : 'primary'}>
            <Typography variant="h4">{50}</Typography>
          </StyledQtFab>)}
        </Box>
      
        <StyledFab
          color="inherit"
          onClick={this.confirm} style={{
            backgroundColor: 'white',
            border: confirmed ? '2px solid #80ff33' : '2px solid black'
          }}
        >
          <CheckIcon fontSize="large" style={{ color: confirmed ? '#80ff33' : 'back' }} />
        </StyledFab>
      </Box>
    );
  }
}

export default ScanPlaceholder;
