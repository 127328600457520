import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React, { Component } from 'react';

import Map from '@app/common/FullMap';
import { IFilter } from '@reducers/filter';
import Loading from '@app/common/Loading';

interface IProps {
  filter: IFilter;
  filterId: string;
  loading: boolean;
  router: any;
  language: string;
}

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  bottom: 20,
  left: 0,
  right: 0,
  margin: '0 auto',
});

class Routing extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.scan = this.scan.bind(this);
  }

  public scan() {
    const { router, language, filter } = this.props;

    const floorplanId = filter.floorplanId || '';

    router.navigate(`/${language}/dashboard/routing/${floorplanId}/scan/location`);
  }

  public render() {
    const { filterId, loading } = this.props;

    return (
      <Box className="tab-content-inner" style={{ position: 'relative' }}>
        <Box style={{ position: 'absolute', top: 0, left: 0, width: '100%', zIndex: 999 }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <ArrowForwardIosIcon />
                <Box pl={1}>Picks</Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box p={2} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <HourglassBottomIcon />
                <Box pl={1}>Pick</Box>
              </Box>
              <Divider />
              <Box p={2} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <HourglassBottomIcon />
                <Box pl={1}>Pick</Box>
              </Box>
              <Divider />
              <Box p={2} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <HourglassBottomIcon />
                <Box pl={1}>Pick</Box>
              </Box>
              <Divider />
              <Box p={2} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <HourglassBottomIcon />
                <Box pl={1}>Pick</Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Loading loading={loading}>
          <Map
            disableOptions={true}
            filterId={filterId}
            forceDisabledLayers={['gmaps']}
            showLiveData
            actions={{ select: {}, hover: {} }}
          />
        </Loading>

        <StyledFab color="primary" onClick={this.scan}>
          <QrCodeScannerIcon fontSize="large" />
        </StyledFab>
      </Box>
    );
  }
}

export default Routing;
